export default class extends Controller {
  static targets= ['hide', 'collapse', 'form', 'targetcharcount', 'copy', "detailCard", 'commonChecklist']
  static values = {
    url: String
  }

  resetSearch(){
    this.formTarget.reset();
    this.formTarget.requestSubmit();
  }

  toggleTimeField() {
    const itemType = this.element.querySelector('.item-type').value
    if (itemType === 'late_checkout') {
      document.getElementById('housekeeping-time-field').classList.remove('d-none')
    } else {
      document.getElementById('housekeeping-time-field').classList.add('d-none')
    }
  }

  submitForm(){
    this.element.requestSubmit();
  }

  hide(){
    this.element.style.display = 'none'
  }

  changeDate(){
    Turbo.visit(`${this.urlValue}/${event.currentTarget.value}`)
  }

  showDetails(){
    this.fetchTurbo(event.params.url)
    document.querySelector('.selected').classList.remove('selected', 'bg-light')
    event.currentTarget.classList.add('selected', 'bg-light')
    document.querySelector(event.params.details).style.display = 'block'
  }

  setActive(){
    document.querySelectorAll('.selected').forEach((element) => element.classList.remove('selected', 'bg-primary'));
    event.currentTarget.classList.add('selected', 'bg-primary')
  }

  toggleDropdown(){
    this.hideTarget.classList.toggle("mobile-d-none")
  }

  showGroupChats(){
    this.fetchTurbo(event.params.url)
    var selected = document.querySelector('.selected')
    if (selected) {
      selected.classList.remove('selected')
    }
    event.currentTarget.children[0].classList.add('selected')
    this.updateNotificationCount(document.querySelector("#hotel-chat-notification-badge"),event.currentTarget.querySelector(".badge"))

    if (window.matchMedia("only screen and (max-width: 768px)").matches) {
      var dropdownHeader = document.querySelector(event.params.dropdown)
      dropdownHeader.innerText = event.currentTarget.innerText
      this.collapseTargets.map(function(e) {
        e.classList.remove('show')
      })
      this.hideTarget.classList.toggle("mobile-d-none")
    }
  }

  updateNotificationCount(side_nav_notification_badge, group_notification_badge){
    var total_notification_count = parseInt(side_nav_notification_badge.innerText)
    var group_notification_count = parseInt(group_notification_badge.innerText)
    var current_notification_count = total_notification_count - group_notification_count
    if (current_notification_count <= 0) {
      side_nav_notification_badge.classList.add('d-none')
    } else {
      side_nav_notification_badge.innerText = current_notification_count
    }
    group_notification_badge.classList.add('d-none')
    group_notification_badge.innerText = ''
  }

  fetchTurbo(url){
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  fetch(){
    event.preventDefault();
    this.fetchTurbo(this.element.href)
  }

  validatePassword(){
    var password = document.getElementById("user_password");
    var confirm_password = document.getElementById("user_password_confirmation");
    if (password.value != ''){
      if(password.value != confirm_password.value) {
        confirm_password.setCustomValidity("Passwords Don't Match");
      }else{
        confirm_password.setCustomValidity("");
      }
    }else{
      confirm_password.setCustomValidity("");
    }
  }


  charcterCounter(){
    this.element.querySelector('.char-counter').textContent = this.targetcharcountTarget.value.length
  }

  toggleMobileView(){
    document.getElementById('guest-chat-section').classList.toggle("mobile-d-none")
  }

  hideGuestChat(){
    this.element.classList.toggle("mobile-d-none")
  }

  showDetailCard(){
    this.detailCardTarget.classList.remove("d-none");
  }

  hideDetailCard(event) {
    this.detailCardTarget.classList.add("d-none");
  }

  scrollToBottom() {
    const element = this.commonChecklistTarget;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }
}
